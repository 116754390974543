@import '../../../styles/theme/variables';

.googleButton {
    background: #4285f4;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
        
    &:hover {
        background-color: #1c46be;
        color: $white !important;
        border-color: transparent !important;
    }
}

.button {
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    width: 100%;
    height: 40px;
    border: 1px solid transparent;
    border-radius: $borderRadius-2;
    font-weight: 700;
}