h1 {
    font-size: 2em;
    font-weight: bolder
}

h2 {
    font-size: 1.5em;
    font-weight: bolder
}

h3 {
    font-size: 1.17em;
    font-weight: bolder
}

h4 {
    font-size: 1em;
    font-weight: bolder
}

h5 {
    font-size: 0.83em;
    font-weight: bolder
}

h5 {
    font-size: 0.67em;
    font-weight: bolder
}