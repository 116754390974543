@use '../signin/signin.module.scss';
@import '../../../styles/theme/variables';
.resetPasswordPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 80px)
}

.resetPasswordContainer {
    padding: 30px 50px 50px 50px;
    border: 1px solid $gray-300;
    border-radius: $borderRadius-3;
}

.formInput {
    min-width: 300px;
}