@import '../../../../styles/theme/variables';

.datesContainer {
    display: flex;
    height: 5rem;
    width: 100%;
}

.dateIconContainer {
    background-color: $primary;
    height: 100%;
    min-width: 60px;
    border-radius: 0 40px 40px 0;
    align-items: center;
    position: relative;

    &:hover:enabled {
        cursor: pointer;
        background-color: $primary-dark-100;
    }
}

.datesWrapper {
    border: 1px solid $gray-300;
    border-right: 0;
    height: 100%;
    width: 100%;
    border-radius: 40px 0 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;

    @media (min-width: 768px) {
        padding-left: 1.25rem;
        padding-right: 1rem;
    }

    @media (max-width: 768px) {
        padding-left: 2rem;
        padding-right: 1rem;
    }
}

.divider {
    border-bottom: 0.5px solid $gray-400;
}

.updateDatesButton {
    background-color: $primary;
    color: $white;
    margin: 5px;
    border-radius: $borderRadius-3;

    &:hover {
        color: $white;
        background-color: $primary-dark-100;
    }
}

.buttonDisable {
    background-color: $gray-400;
    cursor: default;

    &:hover {
        background-color: $gray-400;
    }
}

.datesConfigDatepicker {
    width: 100%;
}