@import '../.././../../styles/theme/variables';

.tripPriceContainer {
    width: 100%;
    margin-bottom: 0.25rem;
    display: flex;
    min-height: 3rem;
    height: 3rem;
}

.tripPriceOverview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    border-radius: 40px 0 0 40px;
    border: 1px solid $gray-300;
    border-right: none;

    @media (min-width: 768px) {
        padding-left: 1.25rem;
        padding-right: 1rem;
    }

    @media (max-width: 768px) {
        padding-left: 2rem;
        padding-right: 1rem;
    }
}

.tripPriceOpenButton {
    background-color: $primary;
    height: 100%;
    min-width: 60px;
    border-radius: 0 40px 40px 0;
    align-items: center;
    position: relative;
    display: flex;
    justify-content: center;
    color: $white;
    cursor: pointer;
    &:hover {
        background-color: $primary-dark-100;
    }
}