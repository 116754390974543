@import './button';
@import './variables';
@import './font';

mark {
    background-color: rgba(245, 187, 84, 0.4)
}

.unselectable {
    -webkit-user-select: none;  /* Safari */
    -moz-user-select: none;     /* Firefox */
    -ms-user-select: none;      /* Internet Explorer/Edge */
    user-select: none;          /* Non-prefixed version, currently supported by Chrome, Opera and Edge */
}

.modal {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
}

.box-shadow-light {
    box-shadow: 0 2px 4px rgba(0, 0, 0, .4);
}

.max-screen-width{
    max-width: $max-screen-width;
}    
