.accountPageBody {
    margin: 50px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.accountPageMenu {
    background-color: transparent;
}

.accountPageMenuItem.ant-menu-item-selected {
    color: red !important
}