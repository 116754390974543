@import '../../../../styles/theme/variables';
@import './members/members.module.scss';

.modalWrap {
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.10);
    top: 0;
}

.modalContent {
    border-radius: $borderRadius-3 !important;
}

.inviteEmailInput {
    resize:none;
    border: 1.5px solid $gray-100;
    border-radius: 12px;
    width: 100%;
    min-height: 44px;
    padding: 0 6px;
    overflow: none;
    outline: none;
    align-items: center;
}

.submitButton {
    background: $primary;
}

.submitButton:hover {
    background-color: $primary-dark-100 !important;
}

.tagify {
    width: 100%
}

.tripMember {
    padding-top: 10px;
}

.tripMemberText {
    font-weight: 600;
}

.currentMembers {
    width: 100%;
    max-height: 180px;
    overflow-y: scroll;
    border-bottom: 1.5px solid $gray-300;
}

.displayName {
    padding: 5px 0 0 5px;
    display: flex;
    align-items: center;
}

.addButton {
    padding: 0.1rem 1rem;
}