@import '../../../styles/theme/variables';

.bodyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 2rem;
    gap: 0.5rem;
    overflow: scroll;
}

.section {
    gap: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input {
    font-size: 30px;
    padding: 0.25rem 1rem;
    text-align: center;
}

.editorSection {
    width: 100%;
    padding: 1rem;
    border-radius: 1rem;
    justify-content: center;
    // border: 0.5px solid $gray-400;
}

.button {
    min-width: 85px;
    height: 28px;
}

