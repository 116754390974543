@import '../../.././../../styles/theme/variables';
@import '../../../../auth/modal/auth-modal.module.scss';

.tripPricePPContent {
    display: flex;
    gap: 8px;
    flex-direction: column;
    width: 100%;
}

.tripPricePPRow {
    padding: 0.5rem 3rem 0.5rem 2rem;
    border-bottom: 1px solid $gray-300;

    &:last-child {
        border: none;
    }
}

.tripPriceCollapse {
    max-height: 35rem;
    overflow-y: auto;
    overflow-x: hidden;
}
.modalHeader {
    padding: 0.5rem 0 1.25rem 0;
}
.formButtonContainer:after {
    width: 21%;
}

.checkbox:checked+label:after {
    right: 28%;
}