@import '../../../../styles/theme/variables';
@import '../../../home/home-main/home-main.module.scss';

.modalWrap {
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.10);
    top: 0;
}

.modalContent {
    border-radius: $borderRadius-3 !important;
}

.createTripContainer {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    border-radius: 1.5rem;
    background-color: white;
    width: 100%;
    gap: 0.5rem;
}