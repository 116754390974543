@import '../../../styles/theme/variables';

.tripsContainer {
    width: 100%;
    height: 30vh;
    max-height: 350px;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    border-bottom: 1px solid $gray-300;
}

.tripsHeader {
    display: flex;
    justify-content: center;
    padding: 20px 20px 10px 20px;
    color: $black;
    font-size: 24px;
    font-weight: 600;
}

.trips {
    flex: 1;
    width: 100%;
    display: flex;
    gap: 1rem;
    overflow-x: scroll;
    padding-left: 1px;
    padding-right: 1px;

    @media (max-width: 768px) {
        padding: 0 1rem;
    }
}

.tripContainer {
    padding: 4px;
    @media (min-width: 768px) {
        width: 25%;
        max-width: 25%;
    }

    @media (max-width: 768px) {
        width: 100%;
        min-width: 100%;
    }
}