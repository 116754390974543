@import '../../../styles/theme/variables';

.wrapper {
    width: 100%;
    display: flex;
    padding: 1rem;
    flex-wrap: wrap;
    justify-content: center;
}

.section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    @media (min-width: 768px) {
        min-width: 50%;
        max-width: 50%;
    }

    @media (max-width: 768px) {
        min-width: 100%;
    }
}

.reversed {
    @media (min-width: 768px) {
        flex-direction: row-reverse;
    }
}

.container {
    max-width: 80%;
}

.feedbackContainer {
    width: 80%;
    max-width: 500px
}

.title {
    margin-bottom: 0.25rem;
    text-align: center;
}

.description {
    @media (min-width: 768px) {
        font-size: 20px;
    }
}
