.container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.cityTags {
    width: fit-content;
    flex-wrap: nowrap;
    border: none;
}

.addCity {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    line-height: 1.25rem;
}