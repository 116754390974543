.cityConfigInputs {
    display: flex;
    gap: 0.5rem;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    input {
        flex: 1;

        @media (max-width: 768px) {
            width: 100%;
            min-height: 2.25rem;
        }

        @media (min-width: 768px) {
            width: 50%;
        }
    }
}