@import '../../../../styles/theme/variables';

.trip {
    background-color: $white;
    border-radius: $borderRadius-3;
    display: flex;
    padding: 0.75rem;
    position: relative;
    height: 100%;
    color: $black;
    flex: 1;
    gap: 0.5rem;
    border: 1px solid $gray-300;
    
    &:hover {
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.4);
        cursor: pointer;
    }
}

.thumbnailContainer {
    max-width: 50%;
    width: 50%;
}

.thumbnail {
    width: 100%;
    height: 100%;
    border-radius: $borderRadius-2;
    border: 0.5px solid $gray-400;
}

.descriptions {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    width: 50%;
    align-items: start;
    justify-content: flex-start;
}

.tripName {
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-align: start;
}
