@import "../../styles/theme/variables";

.pageBody {
    height: 94vh;
    overflow-y: scroll;
}

.sectionContainer {
    justify-content: center;
    padding-top: 20px;
    margin: auto;
    max-width: 560px;
    min-width: 50%;
    gap: 15px;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    padding: 1rem;
}

.sectionTitle {
    color: $black;
    font-size: 18px;
    font-weight: 700;
}

.trip {
    position: relative;
    background-color: $primary;
    color: $white;
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: $borderRadius-3;
    cursor: pointer;

    span {
        z-index: 1;
    }
}

.trip::after {
    content: "";
    position: absolute;
    border-radius: $borderRadius-3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity 1s ease;
    background: linear-gradient(180deg, #00acee, #3dbde1, #eae9e9);
    opacity: 0;
}

.trip:hover::after {
    opacity: 1;
}

.tripDetails {
    display: flex;
    flex-direction: column;
}

.tripActions {
    z-index: 1;
    display: flex;

    button {
        border: 0.5px solid $gray-200;
        width: 35px;
        height: 35px;
        color: $gray-200;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin: 0.5rem
    }

    button:hover {
        border-color: $white;
        color: $white;
    }
}