@import '../../../theme/variables';

.ant-menu {
    transition: width 0.5s cubic-bezier(0.2, 0, 0, 1) 0s;
    justify-content: flex-start !important;
}

.ant-menu-inline {
    width: unset;

    @media (max-width: 768px) {
        width: 100vw !important;
    }
}

.ant-menu-inline-collapsed {
    width: 0px;
}

.ant-menu-light .ant-menu-item-selected {
    background-color: white;
}

.ant-menu-light.ant-menu-root.ant-menu-inline {
    border-inline-end: 0px;
}

.ant-menu-item:not(.ant-menu-item-selected):hover {
    cursor: auto;
    background: transparent !important;
}

.ant-menu-light.ant-menu-horizontal {
    >.ant-menu-item-selected {
        color: inherit;
        cursor: default;

        &::after{
            border-bottom-color: $primary;
        }
    }
}

