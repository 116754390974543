@import '../../theme/variables';

.air-datepicker-global-container {
    z-index: 1001; //index 1 above antd modal's z-index 1000
}
.air-datepicker-body--day-name {
    color: $primary !important;
}

.air-datepicker {
    border-radius: $borderRadius-3;
    padding: 4px;

    &.-inline- {
        width: 100%;
    }

    @media (max-width: 768px) {
        width: 90vw;
    }
}

.air-datepicker--pointer {
    display: none;
}

.air-datepicker-cell {
    &.-range-from-{
        border-radius: 0.75rem 0 0 0.75rem;
    }

    &.-range-to-{
        border-radius: 0 0.75rem 0.75rem 0;
    }
}