@import '../../../../../styles/theme/variables';

.tripMember {
    padding-top: 10px;
}

.tripMemberText {
    font-weight: 600;
}

.currentMembers {
    padding: 10px 0;
    width: 100%;
    height: fit-content;
    max-height: 300px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 5px
}

.displayName {
    padding: 5px 10px 5px 5px;
    display: flex;
    justify-content: space-between;
}

.innerName {
    max-width: 450px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.avatarActive {
    background-color: $primary;
}

.nameLabel {
    padding-left: 10px
}