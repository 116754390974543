@import '../../../styles/theme/variables';

.modalMask {
    background-color: transparent;
}

.modalWrap{
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.10);
    top: 0;
}

.modalContent {
    padding: 0 !important;
    border-radius: 20px !important;
}

.modal {
    outline: none;
}

.modalHeader {
    display: flex;
    justify-content: center;
    padding: 2.5rem 0;
    font-size: 18px;
    font-weight: 600;
}

.titleButton{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    padding: 0 20px;
    font-weight: 700;
    text-align: center;
    width: 50%;
    background-color: transparent;
    z-index: 1;
    white-space: nowrap;
}

.checkbox[type='checkbox']{
    display: none;
}

.formButtonContainer {
    display: flex;
    width: 50%;
    height: 45px;
    border-radius: $borderRadius-3; 
    cursor: pointer;
    background-color: $gray-300;
    align-items: center;
}

.formButtonContainer:after{
    content: "";
    width: 23%;
    right: 51%;
    height: 35px;
    background-color: $primary;
    position: absolute;
    border-radius: $borderRadius-3;
    transition: 0.5s;
}

.checkbox:checked+label:after {
    right: 26%;
}

.modalBody {
    margin: 0 15%;
    padding-bottom: 10%;
}

.selectedTab {
    color: $white
}
