.tagify__input {
    min-width: 0;
    padding: 0;
    margin: 0;
}

.tagify__tag {
    margin-block: 0;
}

.tagify__tag > div::before {
    border-radius: 1rem;
}

.tagify[readonly]:not(.tagify--mix):not(.tagify--select) .tagify__tag > div::before {
    animation: none;
}

.tagify__tag:focus div::before, .tagify__tag:hover:not([readonly]) div::before {
    --tag-bg: unset;
    --tag-bg-inset: 0;
}