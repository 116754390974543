@import '../../../../styles/theme/variables';

.tripNameContainer{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.tripName {
    font-size: 20px;
    padding-left: 0.5rem;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-width: 100%;
}

.tripNameInput {
    max-height: 30px;
    font-size: 20px;
}

.tripNameEdit {
    outline: none;
    color: $primary;
    cursor: pointer;
}

.nameRequiredWarning {
    border-color: red !important;
}