@import '../.././../../styles/theme/variables';

.modalContent {
    background-color: $secondary-300 !important;
}

.notesContainer {
    border: 0.5px solid $gray-300;
    width: 100%;
    border-radius: $borderRadius-2;
    background-color: $secondary-300;
    padding: 0.75rem 0
}

.notesHeader {
    padding: 0 0.75rem;
    height: 30px;
}

.notesInputMinimize {
    width: 100%;
    background-color: transparent;
    height: 300px;
    max-height: 300px;
    overflow-y: scroll;
    padding-left: 15px;
    overflow-x: hidden;
    max-width: 263px;
}

.notesInputMaximize {
    width: 100%;
    background-color: transparent;
    height: 600px;
    max-height: 600px;
    margin-top: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
}


.notesIcon {
    color: $gray-500
}