@import '../../styles/theme/variables';

.dashboardBody {
    height: 94vh; //6vh navbar
    display: flex;
    flex-grow: 1;
    padding-bottom: 1rem;
}

.dashboardBodyMobile {
    height: 100%;
}

.noAccessBox {
    width: 500px;
    height: 300px;
    gap: 8px;
    border-radius: $borderRadius-2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.errorText {
    text-align: center;
}

.requestButton {
    max-width: 150px;
    align-self: center;
}