@import url('https://fonts.googleapis.com/css2?family=Karla&family=Pacifico&display=swap');
@import "sc-calendar/style.css";
@import 'air-datepicker/air-datepicker.css';
@import './styles/overrides/antd/style.scss';
@import './styles/overrides/air-datepicker/style.scss';
@import './styles/overrides/editor-js/style.scss';
@import './styles/overrides/tagify/style.scss';
@import "@yaireo/tagify/dist/tagify.css";
@import './styles//theme/global';
@tailwind base;
@tailwind components;
@tailwind utilities;

*::before,
* {
  font-family: 'Karla', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

*::-webkit-scrollbar {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  box-shadow: none;
  background-color: $gray-300;
  background-clip: padding-box;
  border-radius: 8px;
  min-height: 40px;
}

*:hover::-webkit-scrollbar-thumb {
  background-color: $gray-300;
}

*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: inherit;
}

body {
  height: 100%;
  min-height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: -webkit-fill-available;
}