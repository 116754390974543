.codex-editor__redactor {
    padding-bottom: 100px !important;
    height: fit-content !important;
}

.codex-editor__loader {
    height: fit-content !important;
}

.ce-block__content {
    overflow: hidden;
}

#editorjs .ce-block__content {
    max-width: 850px; /* Your desired width */
    margin: 0 auto; /* Center the content */
    position: relative;
}

#editorjs .ce-toolbar__content {
    max-width: 850px;
}

.inline-image__caption {
    display: none;
}