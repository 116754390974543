

.button {
    background-color: white;
    color: red;
    border: 1px solid red;
    border-radius: 10px;
    width: 70px;
    height: 30px;
    &:hover {
        background: red;
        color: white;
        transition: 0.5s;
    }
}