@import '../../../styles/theme/variables';

.formGroup {
    width: 100%;
    border-bottom: 1px solid;
    display: flex;
    height: 35px;
    align-items: center;
    padding: 0px 50px;

    &:last-child {
        border: none
    }
}

.formBody {
    .formInput {
        width: 100%;
        height: 40px;
        border: 1px solid $primary;
        border-radius: $borderRadius-1;
        padding: 1rem;
        font-size: 16px;
        box-shadow: none;
    }
}

.button {
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    width: 100%;
    height: 40px;
    border: 1px solid transparent;
    border-radius: $borderRadius-2;
    font-weight: 700;
}


.primaryButton {
    background: $primary;
    color: $white;
    
    &:hover {
        background-color: $primary-dark-100;
        color: $white !important;
        border-color: transparent !important;
    }
}

.secondaryButton {
    background: $gray-300;
    color: $black;
    font-weight: 500;

    &:hover {
        background-color: $gray;
    }
}

.formInputError {
    color: red;
    min-height: 30px;
}

.formAuthError {
    color: red;
    min-height: 30px;
    display: block;
    text-align: center;
}

.text {
    color: #1b2839;
    font-weight: 500;

    @media (max-width: 768px) {
        font-size: 14px;
    }
}