@import '../../../styles/theme/variables';

.container {
    padding: 1rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid $gray-300;
}

.itemsContainer {
    display: flex;
    gap: 16px;

    span {
        &:hover{
            color: $primary;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}