@import '../../../theme/variables';

.ant-radio-button-wrapper {
    &:first-child{
        border-start-start-radius: $borderRadius-3 !important;
        border-end-start-radius: $borderRadius-3 !important;
    }
    &:last-child{
        border-start-end-radius: $borderRadius-3 !important;
        border-end-end-radius: $borderRadius-3 !important;
    }

    &:hover{
        color: $black;
        background-color: $gray-100;
    }
}

.ant-radio-button-wrapper-checked {
    color: $white !important;
    background-color: $primary !important;
    border-color: transparent !important;

    &::before {
        background-color: $primary !important;
    }
}

