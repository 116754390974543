@import "../../../theme/variables";

.ant-btn {
    border-radius: $borderRadius-3;
    outline: none !important;
}

.ant-btn-primary {
    background-color: $primary;
    color: $white;

    &:hover {
        background-color: $primary-dark-100 !important;
    }
    
    &:disabled {
        background-color: $gray-200 !important;
    }
}

.ant-btn-secondary {
    background-color: $secondary;
    color: $white;

    &:hover {
        background-color: $secondary-dark-100 !important;
    }
    
    &:disabled {
        background-color: $gray-200 !important;
    }
}
.ant-btn-default {
    background-color: $gray-300;
    color: $black !important;

    &:hover {
        background-color: $gray !important;
        border-color: transparent !important;
    }
}

.sc {
    .ant-btn-default:disabled:hover {
        cursor: default;
        background: rgba(0, 0, 0, 0.04) !important;
        color: $black !important;   
        border-color: #d9d9d9 !important; 
    }
}