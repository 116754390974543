@import '../../../styles/theme/variables';

.inviteButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: $white !important;
    width: 100%;
    height: 35px;
    border-radius: $borderRadius-3;
    min-width: 100px !important;
}

.menuWrapper {
    overflow-y: auto;
    padding: 0.5rem;
    padding-right: 0;
    background-color: $white;
    z-index: 1;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
    min-height: 100%;
    border: 1px solid $gray-300;
    border-radius: $borderRadius-4;
    width: 100%;
    padding: 1rem;
}

.topContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: fit-content;

    &:last-child {
        flex: 1;
        align-items: end;
    }
}

.widescreenSidebar {
    min-width: 325px;
    max-width: 325px;
    padding-inline-start: 1rem;
}

.sidebarFooter {
    width: 100%;
    height: 100%;
}

.searchEventButton {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.5rem;
    font-size: 16px;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 2rem;
    cursor: pointer;
    background: linear-gradient(270deg, #F5BB5E, #f9903f, #F5BB5E, #ffa033);
    background-size: 600% 600%; /* Larger background size for a flowing effect */
    transition: all 0.4s ease-in-out;
    position: relative;
    z-index: 0;
    overflow: hidden;
    animation: flow 8s ease infinite; /* Slow, continuous animation */
}

@keyframes flow {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.searchEventButton:hover {
    filter: brightness(1.1);
}
