@import '../../../styles/theme/variables';

.accountProfileContainer {
    padding: 20px;

}

.accountProfileTitle {
    font-size: 20px;
    font-weight: 700;
}

.accountProfileRow {
    display: flex;
    gap: 20px;
    padding: 10px;
    align-items: center;
    min-height: 55px;
    flex-wrap: wrap;
}

.accountProfileLabel {
    font-size: 16px;
    font-weight: 600;
    width: 25%;
    min-width: 200px;
}

.accountProfileInput {
    border: 0.5px solid $gray-400;
    padding: 0.3rem 1rem;
    border-radius: 15px;
}

.accountProfileSpan {
    min-width: 200px
}

.accountProfileInput:focus {
    border: 1px solid $gray-500 !important;
    outline: none;
    box-shadow: none;
}

.formInputError {
    color: red;
}

.customButton {
    max-height: 35.59px;
}