//Border-radius
$borderRadius-1: 0.5rem;
$borderRadius-2: 1rem;
$borderRadius-3: 1.5rem;
$borderRadius-4: 2rem;

//Color
// $primary: #3dbde1;
$primary-100: #eefaff;
$primary-dark-100: #dcf5ff;
$primary-300: #caf0ff;
$primary-400: #b8eaff;
$primary-500: #a5e5ff;
$primary-600: #91dfff;
$primary-700: #7bdaff;
$primary-800: #63d4ff;
$primary-900: #44ceff;
$primary: #00c8ff;
$primary-dark-100: #0cb0dd;

$secondary: #F5BB5E;
$secondary-dark-100: #F1A326;

$secondary-900: #F6C271;
$secondary-800: #F8C983;
$secondary-700: #F9D093;
$secondary-600: #FAD7A3;
$secondary-500: #FBDEB3;
$secondary-400: #FCE5C3;
$secondary-300: #FDEBD2;
$secondary-200: #FEF2E1;
$secondary-100: #FEF8F0;

$gray: #b6b6b696;
$white: #ffffff;
$black: #040404;

//Gray scale
$gray-100: #F4F4F7;
$gray-200: #ECECF1;
$gray-300: #DCDCE4;
$gray-400: #9999A0;
$gray-500: #51555A;
$gray-600: #2C2D2F;
$gray-700: #131314;

$danger: #ff3f3f;

$max-screen-width: 2440px