.antTabs {
    width: 400px;
    height: 400px;
    border: 1px solid grey;
    border-radius: 20px;
    padding: 10px;
    /* backdrop-filter: blur(5px); */
    box-shadow: 2px 2px grey;
}

.redirectPage {
    /* background-image: url("../../../assets/background/pxfuel.jpg"); */
    background-size: cover;
}