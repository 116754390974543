@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap');
@import '../../../styles/theme/variables';

.homeContent {
    width: 100%;
    display: inline-block;
    background: linear-gradient( rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.2) ), url('../../../assets/background/home-main.png');
    background-repeat: no-repeat;
    border-radius: 2rem;
    background-size: cover;
    background-position: right;
}

.homeTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 3rem 0;
    gap: 3rem;
    height: 100%;
    min-height: 64vh;
}

.homeTitleContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
}
.homeTitle {
    font-family: "Lato", sans-serif;
    color: $white ;
    text-align: center;
    text-shadow: 2px 4px 10px rgba(0, 0, 0, 0.5);
}

.homeInputsContainer {
    display: flex;
    flex-direction: column;
    padding: 1rem 1.5rem;
    border-radius: 1.5rem;
    background-color: white;
    width: 30%;
    min-width: 530px;
    max-width: 50rem;
    gap: 0.5rem;
}

.homeInputsAction {
    flex: 3;
    border-radius: 50px;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
    min-width: 75px;
}

.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
}


@media (max-width: 768px) {


    .homeContent {
        border-radius: 0;
    }

    .homeInputsContainer { 
        width: 90%;
        flex-direction: column;
        height: fit-content;
        padding: 20px 30px;
        min-width: 0;
        gap: 8px
    }

    .homeInputsAction {
        visibility: visible !important;
        opacity: 1;
    }

    .homeInput {
        padding: 0 10px;
    }
}