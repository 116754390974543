.layout {
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    min-height: -webkit-fill-available;
    height: 100%;
    max-width: 100%;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
}

.layout::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

@supports (height: 100dvh) {
    .layout {
        height: 100dvh;
    }
}