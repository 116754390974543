@import '../../styles/theme/variables';

#schavelHomepage {
    width: 100%;
    padding: 0 2rem;
    height: 94vh; //6vh navbar
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    align-items: center;
}

.homepageContainer {
    max-width: $max-screen-width;
    justify-content: center;
    display: flex;
    flex-direction: column;
}


#schavelHomepage::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

@media (max-width: 768px) {

    #schavelHomepage {
        padding: 0
    }
}