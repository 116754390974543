.ant-modal-root .ant-modal-mask {
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.10);
    top: 0;
}

.ant-modal-content {
    border-radius: $borderRadius-3 !important;
}