@import '../../../styles/theme/variables';

.modalWrap {
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.10);
    top: 0;
}

.modalContent {
    border-radius: $borderRadius-3 !important;
}