.primary-button {
    background-color: $primary;
    padding: 0 10px;
    border-radius: 15px;
    color: $white;
}

.primary-button:disabled {
    background-color: $gray-300;
}

.btn-disabled {
    background-color: $gray-300 !important;
    cursor: default !important;
}