@import '../../styles/theme/variables';

.header {
    height: 6vh;
    display: flex;
    width: 100%;
    justify-content: center;

    @media (min-width: 768px) {
        padding: 0 2rem;
    }

    @media (max-width: 768px) {
        margin: 0.25rem 0 0.25rem 0;
    }
}

.navigation {
    height: 100%;
    background-color: transparent;
    background-color: white;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.navContainer {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;

    @media (min-width: 768px) {
        gap: 0.5rem;
    }
    @media (max-width: 768px) {
        gap: 0.5rem;
    }
}

.navContainer.navMiddle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-height: 6vh;
}

.logo {
    cursor: pointer;
    height: 95%;
}

.sidebarButton {
    border: 0.25px solid $gray-300;
    border-radius: 1.5rem;
    padding: 0.25rem;
    box-shadow:  1px 2px 0 rgba(0, 0, 0, 0.08);
    color: $primary;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.5rem;

    &:hover {
        box-shadow:  1px 1px 7px rgba(0, 0, 0, 0.2);
    }
}



.navUserContainer {
    position: relative;
}

.navUser {
    border: 1px solid $gray;
    width: fit-content;
    height: 40px;
    padding: 0 12px;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}

.iconBar{
    font-size: 14px;
}

.navUserDropdown {
    display: none;
    position: absolute;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;;
    right: 0;
    width: 175px;
    margin-top: 6px;
    padding: 8px 0;
    border-radius: $borderRadius-2;
    background-color: white;
    z-index: 11;

    &.dropdownShow{
        display: block;
    }
}


.navigationItem {
    list-style-type: none;
    cursor: pointer;
    width: 100%;
    padding: 7px 15px;

    &:hover{
        background-color: #efefef90
    }
}

.signUpButton {
    height: 40px;
    border-radius: 40px;
}

.navItem {
    min-width: 75px;
    border-bottom: 2px solid transparent;

    &:hover {
        color: $primary;
        border-color: $primary;
    }

}

.navItemActive {
    color: $primary;
    border-color: $primary;
}

@media (max-width: 768px) {

    .navigation {
        padding: 0 1rem;
    }
}